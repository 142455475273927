@import 'variables';

.mat-error {
  white-space: pre-line;
}

.eos-control-width-100 {
  .mat-form-field {
    width: 100%;
  }
}

.eos-control-wrapper-p-0 {
  .mat-form-field-wrapper {
    padding-bottom: 1px;
  }

  .mat-form-field-underline {
    bottom: 1px;
  }

  .mat-form-field-infix {
    width: 100%;
  }
}

.mat-input-element {
  &.eos-control-danger {
    color: $danger-text;
  }
}

.eos-hint {
  &.warning {
    color: $warning-text;
    background-color: $warning-bg;
  }
}
