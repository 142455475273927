.eos-integration-container {
  & > .container-fluid > .row > div {
    padding: 0;

    &:first-of-type {
      margin-bottom: 20px;
    }
  }
}

@media (min-width: 768px) {
  .eos-integration-container {
    & > .container-fluid > .row > div {
      &:first-of-type {
        padding-right: 10px;
        margin: 0;
      }

      &:last-of-type {
        padding-left: 10px;
      }
    }
  }
}
