.eos-chips-autocomplete-container {
  position: relative;

  & > div {
    position: absolute;
    top: -2px;
    right: 0;

    button {
      line-height: normal;
      font-size: 12px;
      font-weight: 300;
      padding: 0;
    }
  }
}

.eos-chips-autocomplete-container,
.eos-chips-input-container {
  .mat-standard-chip.mat-chip-with-trailing-icon {
    min-height: 24px;
    height: auto;
    line-height: 1.1em;
    padding: 1px 8px 1px 12px;
  }
}

@media (min-width: 768px) {
  .eos-chips-autocomplete-container,
  .eos-chips-input-container {
    .mat-standard-chip.mat-chip-with-trailing-icon {
      margin: 0 4px;
    }
  }
}

/*
* === FLAG, ASSIGNEE ===
*/
.eos-chips-autocomplete-container {
  .eos-flag-chips-autocomplete-renderer,
  .eos-assignee-chips-autocomplete-renderer {
    font-size: 12px;

    .mat-form-field-wrapper {
      padding-bottom: 1px;
    }

    .mat-form-field-underline {
      bottom: 1px;
    }

    .mat-chip-list-wrapper {
      flex-wrap: nowrap;

      .mat-standard-chip,
      .mat-standard-chip.mat-chip-with-trailing-icon {
        min-height: 20px;
        padding: 2px 4px 2px 6px;
        margin: 1px 2px 4px;

        .mat-icon {
          height: 16px;
          width: 16px;
          font-size: 16px;
        }

        button {
          margin-left: 0;
        }
      }

      input {
        width: 50px;
        flex: 1 0 50px;
        margin: 4px;
      }
    }
  }
}
