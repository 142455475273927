@import 'variables';

.eos-flag {
  &-red,
  &-red-outlined {
    color: $flag-red;
  }

  &-blue,
  &-blue-outlined {
    color: $flag-blue;
  }

  &-yellow,
  &-yellow-outlined {
    color: $flag-yellow;
  }

  &-purple,
  &-purple-outlined {
    color: $flag-purple;
  }

  &-pink,
  &-pink-outlined {
    color: $flag-pink;
  }

  &-black,
  &-white {
    color: $flag-black;
  }

  &-orange,
  &-orange-outlined {
    color: $flag-orange;
  }

  &-green,
  &-green-outlined {
    color: $flag-green;
  }

  &-brown,
  &-brown-outlined {
    color: $flag-brown;
  }
}
