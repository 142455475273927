@import 'variables';
@import 'mixins';

/*
* Fix https://github.com/ag-grid/ag-grid/issues/2213
*/
.ag-layout-normal > .ag-body-viewport.ag-layout-normal {
  overflow-y: overlay;
}

/*
* === BASE CLASS ===
*/
.eos-table {
  .ag-header {
    background-color: $table-header-bg;

    &-cell {
      border-left: 1px solid $table-border;
      padding: 0 12px;

      &-text {
        white-space: normal;
        word-break: break-word;
      }

      .ag-sort-indicator-icon {
        padding: 0;
      }
    }
  }

  .ag-status-bar {
    border-width: 0 0 1px 0;
    padding-right: 0;
  }

  .ag-status-bar-center {
    flex: 0 0 100%;
  }

  .ag-row-loading {
    display: none;
  }

  .ag-row-animation .ag-row {
    transition: transform .4s, top .4s;
  }

  .ag-row {
    &.eos-table-row-success {
      background-color: $table-success-bg;

      &.ag-row-hover:not(.ag-full-width-color)::before {
        background-color: $table-success-bg-hover;
      }
    }

    &.eos-table-row-info {
      background-color: $table-info-bg;

      &.ag-row-hover:not(.ag-full-width-color)::before {
        background-color: $table-info-bg-hover;
      }
    }

    &.eos-table-row-warning {
      background-color: $table-warning-bg;

      &.ag-row-hover:not(.ag-full-width-color)::before {
        background-color: $table-warning-bg-hover;
      }
    }

    &.eos-table-row-danger {
      background-color: $table-danger-bg;

      &.ag-row-hover:not(.ag-full-width-color)::before {
        background-color: $table-danger-bg-hover;
      }
    }

    &.ag-row-hover {
      cursor: pointer;
    }

    &.ag-row-inline-editing .ag-cell-inline-editing {
      height: 50px;

      .ag-cell-wrapper {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ag-cell {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    border-left: 1px solid $table-border;
    white-space: normal;
    padding: 0 12px;

    &-auto-height {
      padding: 16px 12px;
    }

    &-wrapper,
    &-value,
    .mat-form-field {
      width: 100%;
    }

    &-wrapper {
      &.ag-row-group {
        align-items: center;
      }

      .ag-group-expanded,
      .ag-group-contracted {
        margin-right: 6px;
      }
    }

    &.eos-table-cell-default {
      color: $default-text;
    }

    &.eos-table-cell-success {
      color: $success-text;
    }

    &.eos-table-cell-info {
      color: $info-text;
    }

    &.eos-table-cell-warning {
      color: $warning-text;
    }

    &.eos-table-cell-danger {
      color: $danger-text;
    }

    &.eos-table-cell-payment-status-success .ag-cell-value,
    &.eos-table-cell-payment-status-warning .ag-cell-value,
    &.eos-table-cell-payment-status-danger .ag-cell-value {
      font-weight: 800;
      color: $white;
      text-align: center;
      border-radius: 6px;
      padding: 6px;
    }

    &.eos-table-cell-payment-status-success .ag-cell-value {
      background-color: $payment-status-success-bg;
    }

    &.eos-table-cell-payment-status-warning .ag-cell-value {
      background-color: $payment-status-warning-bg;
    }

    &.eos-table-cell-payment-status-danger .ag-cell-value {
      background-color: $payment-status-danger-bg;
    }

    &.ag-cell-data-changed {
      background-color: $table-success-bg !important;
    }

    .ag-row-group-leaf-indent {
      margin: 0;
    }

    &.ag-cell-focus:not(.ag-cell-range-selected) {
      border: 2px solid $brand-primary !important;
    }

    &.eos-table-cell-text-ellipsis .ag-cell-wrapper {
      overflow: hidden;
    }
  }
}

/*
* === RENDERERS ===
*/
.eos-table-select-renderer-container {
  .mat-form-field-wrapper {
    padding-bottom: 1px;
  }

  .mat-form-field-underline {
    bottom: 1px;
  }
}

.eos-table-reactive-order-renderer-container {
  .mat-form-field-wrapper {
    padding-bottom: 1em;
  }

  .mat-form-field-underline {
    bottom: 0.8em;
  }

  .mat-form-field-infix {
    padding: 0.3em 0;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 0.8em;
  }
}

.mat-autocomplete-panel.eos-table-reactive-order-renderer {
  min-width: 180px;

  .mat-option {
    font-size: 12px;
  }
}

.eos-table-dropdown-renderer-container,
.eos-table-action-renderer-container {
  & > button {
    padding: 0;
  }
}

.eos-table-action-renderer-container > button {
  width: 76px; // cell width 100px - padding 2 * 12
  font-size: 12px;
  font-weight: normal;
  text-align: left;
}

.eos-table-note-detail-renderer-container {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: $table-header-bg;
}

.eos-table-icon-renderer-container {
  display: flex;
  justify-content: center;

  .mat-icon {
    @include icon-size(22px);
  }
}

/*
* === EDITORS ===
*/
.eos-table-status-editor-panel,
.eos-table-researcher-editor-panel {
  margin-top: 36px;
}

.eos-table-flag-chip-list-renderer-container {
  .mat-chip-list-wrapper {
    margin: 0;

    .mat-standard-chip,
    .mat-standard-chip.mat-chip-with-trailing-icon {
      min-height: 20px;
      padding: 2px 4px 2px 6px;
      margin: 1px;

      .mat-icon {
        height: 16px;
        width: 16px;
        font-size: 16px;
      }

      button {
        margin-left: 0;
      }
    }
  }
}

/*
* === STATUS BAR PANELS ===
*/
.eos-table-pagination-container {
  display: flex;
  justify-content: space-between;

  .eos-table-pagination-page-size .mat-form-field {
    width: 100px;
  }

  .eos-table-pagination-pages,
  .eos-table-pagination-row-summary {
    display: flex;
    align-items: center;
  }

  .eos-table-pagination-pages {
    & > *:not(:last-child) {
      margin-right: 5px;
    }

    .mat-form-field-wrapper {
      margin: 0;
      padding: 0;
    }

    .mat-form-field-infix {
      width: 20px;
      border-top: 0.4em solid transparent;
      padding: 0.4em 0 !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  .eos-table-pagination-row-summary-container,
  .eos-table-pagination-controls {
    display: flex;
    align-items: center;
    font-weight: 400;
  }

  .eos-table-pagination-row-summary-container > div {
    margin: 0 24px;
  }

  .eos-table-pagination-row-summary span:not(:last-child) {
    margin-right: 5px;
  }

  .eos-table-pagination-controls {
    button {
      @include btn-icon-size(18px, 9px);
      margin: 0 8px;
    }

    .mat-icon {
      @include icon-size(18px);
    }
  }
}

/*
* === COMPONENTS ===
*/
.eos-order-table-container,
.eos-order-issue-list-container {
  .eos-table {
    margin-top: 20px;
  }
}

.eos-inactive-user-list-container > div {
  font-size: 12px;
  line-height: 17px;
  color: $info-text;
  background-color: $info-bg;
  border: 1px solid $info-border;
  border-radius: 2px;
  padding: 16px;
  margin-bottom: 16px;

  p {
    margin: 0;
  }
}

.eos-orders-by-status-container fieldset {
  padding-top: 20px;
}

.eos-completed-orders-by-researcher-report-container > p {
  text-align: right;
  margin-right: 22px;
}
