.eos-checkbox-container,
.eos-checkbox-group-container {
  p {
    margin: 10px 0;
  }

  .eos-checkbox-label,
  .eos-checkbox-group-label {
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
  }

  &.horizontal {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      padding: 0;

      & ~ p {
        margin: 0 0 0 10px;
      }
    }
  }
}

@media (min-width: 768px) {
  .eos-checkbox-container,
  .eos-checkbox-group-container {
    &.horizontal p ~ p{
      margin: 0 0 0 16px;
    }
  }
}
