@use 'sass:map';
@use '@angular/material' as mat;

$white: #FFFFFF;
$black: #000000;
$red: #FF0000;
$purple: #800080;
$hot-pink: #FF69B4;
$green: #008000;
$saddle-brown: #8B4513;

$grey-palette: mat.define-palette(mat.$grey-palette);
$grey-50: mat.get-color-from-palette($grey-palette, 50);
$grey-105: #F3F3F3;
$grey-110: #F2F7F7;
$grey-bg: #F8F8F8;
$grey-border: #DDDDDD;

$light-green-palette: mat.define-palette(mat.$light-green-palette);
$light-green-500: mat.get-color-from-palette($light-green-palette, 500);

$red-palette: mat.define-palette(mat.$red-palette);
$red-500: mat.get-color-from-palette($red-palette, 500);

$cyan-palette: mat.define-palette(mat.$cyan-palette);
$cyan-500: mat.get-color-from-palette($cyan-palette, 500);

$brand-default: #777777;
$brand-primary: #FF6500;
$brand-success: $light-green-500;
$brand-dander: $red-500;
$brand-info: $cyan-500;

$brand-primary-text: #3D4051;

$default-text: $brand-default;

$success-text: #687F42;
$success-bg: #F0FBE3;
$success-border: #EBF8CD;

$info-text: #3B799A;
$info-bg: #E6F5FD;
$info-border: #C5F1FA;

$warning-text: #9A7E26;
$warning-bg: #FFFAED;
$warning-border: #FFECD4;

$danger-text: #A1513C;
$danger-bg: #FBE9E6;
$danger-border: #F7D0D1;

/*
* === NOTIFICATION ===
*/
$notification-text: #3D4051;
$notification-info-text: $info-text;
$notification-warning-text: $warning-text;

$notification-success-bg: #F3FAF3;
$notification-warning-bg: $warning-bg;
$notification-danger-bg: #FDF7F7;
$notification-info-bg: $info-bg;

$notification-info-border: #C5F1FA;
$notification-warning-border: $warning-border;

// $instruction-text: #7A756F;
$instruction-bg: #AED580;
$instruction-border: $brand-success;
// $instruction-indicator: #F2AF00;

/*
* === TABLE ===
*/
$table-header-bg: $grey-bg;

$table-success-bg: $success-bg;
$table-success-bg-hover: $success-border;

$table-info-bg: $info-bg;
$table-info-bg-hover: $info-border;

$table-warning-bg: $warning-bg;
$table-warning-bg-hover: $warning-border;

$table-danger-bg: $danger-bg;
$table-danger-bg-hover: $danger-border;

$table-border: #E2E2E2;

/*
* === NAV ===
*/
$nav-bg: #262B33;
$nav-bg-active: #1F242A;
$nav-text: rgba(255, 255, 255, 0.5);
$nav-text-active: rgba(255, 255, 255, 0.8);

/*
* === FLAG ===
*/
$flag-red: $red;
$flag-blue: #337AB7;
$flag-yellow: $brand-primary;
$flag-purple: $purple;
$flag-pink: $hot-pink;
$flag-black: $black;
$flag-orange: $brand-primary;
$flag-green: $green;
$flag-brown: $saddle-brown;

/*
* === CHIP LIST ===
*/
$chip-list-item-default-bg: $brand-default;
$chip-list-item-info-bg: $brand-info;
$chip-list-item-success-bg: $brand-success;
$chip-list-item-warning-bg: $brand-primary;
$chip-list-item-danger-bg: $brand-dander;

/*
* === PAYMENT STATUS ===
*/
$payment-status-success-bg: #8BC34A;
$payment-status-warning-bg: $brand-primary;
$payment-status-danger-bg: #F44336;

$bg-body: $grey-105;

$border: rgba(34, 34, 34, 0.1);

$link-hover-color: #B34700;

$image_path: "~assets/images";
