@mixin text-overflow() {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

@mixin icon-size($size, $margin: 0) {
  height: $size;
  width: $size;
  font-size: $size;
  line-height: $size;

  @if $margin != 0 {
    margin-right: $margin;
  }
}

@mixin btn-icon-size($size, $line-height: $size) {
  height: $size;
  width: $size;
  font-size: $size;
  line-height: $line-height;
}
