@import 'variables';
@import 'mixins';

/*
* === BASE CLASS ===
*/
.eos-panel-container {
  .mat-expansion-panel {
    background: transparent;

    &-header {
      height: auto;
      min-height: 48px;
      background-color: $grey-105 !important;
      border-radius: 0;
      border-bottom: 1px solid $brand-primary;
      padding: 6px 24px;

      &[aria-disabled=true] {
        color: rgba(0, 0, 0, .87);
      }

      .mat-content {
        flex-direction: column;
        align-items: flex-start;
      }

      &-title {
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        text-transform: uppercase;

        .mat-icon {
          margin-right: 10px;
        }

        h4 {
          @include text-overflow();
          font-size: 14px;
          font-weight: 500;
          margin: 0 20px 0 0;
        }

        .mat-chip {
          min-height: 22px;
          font-size: 12px;
          color: $white;
          margin: 8px 4px;

          &.eos-chip-list-item-default {
            background-color: $chip-list-item-default-bg;
          }

          &.eos-chip-list-item-info {
            background-color: $chip-list-item-info-bg;
          }

          &.eos-chip-list-item-success {
            background-color: $chip-list-item-success-bg;
          }

          &.eos-chip-list-item-warning {
            background-color: $chip-list-item-warning-bg;
          }

          &.eos-chip-list-item-danger {
            background-color: $chip-list-item-danger-bg;
          }
        }
      }

      &-description {
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        & > div button ~ button {
          margin-left: 10px;
        }
      }
    }

    &-body {
      padding: 0;
    }

    .eos-search-box-container {
      padding: 0 10px;
    }
  }
}

@media (min-width: 768px) {
  .eos-panel-container .mat-expansion-panel {
    &-header {
      height: 48px;

      .mat-content {
        flex-direction: row;
        align-items: center;
      }

      &-title {
        flex-direction: row;
        align-items: center;
      }

      &-description {
        flex-direction: row;
      }
    }
  }
}

/*
* === COMPONENTS ===
*/
.oes-order-accounting-panel-container {
  form {
    padding: 24px 12px;

    button.eos-btn-primary {
      width: 100%;
      margin-top: 20px;
    }

    fieldset {
      border: none;
      padding: 0;
      margin: 0;

      & > legend {
        font-size: 16px;
        font-weight: 500;
        color: $brand-primary;
        margin-bottom: 16px;
      }

      &:last-of-type {
        margin-top: 24px;
      }
    }
  }

  .oes-order-accounting-panel-hard-cost-container,
  .oes-order-accounting-panel-hard-cost {
    legend {
      margin-bottom: 0;
    }
  }

  .oes-order-accounting-panel-hard-cost {
    border: 1px groove $grey-border;
    padding: 24px;
  }
}

.oes-order-marketer-panel-container,
.eos-email-panel-container,
.oes-settings-panel-container,
.oes-integrations-panel-container,
.eos-resware-panel-container {
  form {
    padding: 24px 12px;

    fieldset {
      border: 1px groove $grey-border;
      padding: 24px;
    }

    button.eos-btn-primary {
      width: 100%;
      margin-top: 20px;
    }
  }
}

.eos-avatar-panel-container {
  .eos-avatar-panel {
    padding: 24px 12px;
  }

  .eos-avatar-panel-image-container {
    display: flex;
    justify-content: center;
    height: 170px;

    img {
      border: 1px solid $grey-border;
      background-color: $grey-105;
      object-fit: cover;
    }
  }
}

.oes-order-new-panel-container .eos-flag-chips-autocomplete-renderer .mat-form-field-infix {
  width: 280px;
}

.eos-file-panel-container,
.eos-template-file-panel-container {
  .eos-drag-drop-container {
    border: 2px dashed transparent;

    &.active {
      border-color: $brand-primary;
    }
  }
}

.eos-payment-panel-container {
  .eos-payment-panel-total,
  .eos-payment-panel-balance {
    display: flex;
    font-size: 12px;
    padding: 0 24px;
  }

  .eos-payment-panel-total {
    justify-content: space-between;
  }

  .eos-payment-panel-balance {
    justify-content: flex-end;
  }
}

.oes-batch-draft-panel-container .oes-batch-draft-panel-content {
  padding: 24px;

  fieldset {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  legend {
    font-size: 16px;
    font-weight: 500;
    color: $brand-primary;
  }

  p {
    text-align: justify;
  }

  button {
    width: 50%;
  }
}
