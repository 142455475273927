@import 'variables';

/* Mobile */
.eos-search-box-container {
  fieldset {
    border: none;
  }

  /* Search section */
  .eos-search-box-search-section {
    padding: 20px 10px;

    & > div {
      &:first-of-type {
        order: 2;
      }

      &:last-of-type {
        order: 1;
        margin-bottom: 20px;
      }
    }

    .row {
      justify-content: space-between;
      align-items: center;

      .mat-form-field {
        margin-bottom: 10px;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .eos-search-box-search-section-controls {
    display: flex;
    justify-content: flex-end;

    button {
      width: 100%;

      & ~ button {
        margin-left: 10px;
      }
    }
  }

  .eos-search-box-search-section-actions {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 100%;

      & ~ button {
        margin-top: 10px;
      }
    }
  }

  /* Filter section */
  .eos-search-box-filter-section {
    border-top: 1px solid $border;
    padding-bottom: 20px;

    &-controls button {
      width: 100%;
      text-align: right;
    }

    .mat-datepicker-toggle {
      font-size: 20px;
    }

    .eos-radio-group-container.horizontal {
      margin-bottom: 10px;
    }
  }
}

/* Desktop */
@media (min-width: 1120px) {
  /* Search section */
  .eos-search-box-container {
    .eos-search-box-search-section {
      & > div {
        &:first-of-type {
          order: 1;
        }

        &:last-of-type {
          order: 2;
          margin-bottom: 0;
        }
      }

      .row {
        .mat-form-field {
          margin: 0;
        }
      }
    }

    .eos-search-box-search-section-actions {
      flex-direction: row;

      button {
        width: auto;

        & ~ button {
          margin: 0 0 0 10px;
        }
      }
    }
  }
}
