.eos-batch-update-container {
  padding: 0 12px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .eos-batch-update-type {
    display: flex;
    align-items: center;

    .mat-icon {
      margin-right: 10px;
    }
  }

  button {
    width: 100%;
    margin: 10px 0;
  }

  &.eos-order-ticket-batch-update-container .mat-form-field-infix {
    width: 260px;
  }
}

@media (min-width: 1120px) {
  .eos-batch-update-container {
    form {
      height: 60px;
      flex-direction: row;
      justify-content: space-between;

      & > button {
        margin-left: 16px;
      }
    }

    .eos-batch-update-controls {
      margin-left: 16px;
    }

    button {
      width: auto;
      margin: 0;
    }
  }
}
