@import 'variables';

.eos-editor-container {
  .ng-touched.ng-invalid {
    .ql-toolbar {
      border-bottom-color: $brand-dander;
    }

    .ql-container {
      border-color: $brand-dander;
    }
  }

  label {
    font-size: 10.5px;
    color: rgba(0, 0, 0, .54);
  }

  .ql-toolbar {
    margin-top: 6px;
  }

  .ql-container {
    height: auto;
    min-height: 200px;

    &.ql-disabled {
      border: none;

      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
        background-size: 4px 100%;
        background-repeat: repeat-x;
      }

      .ql-editor {
        padding: 6px 0;
      }
    }
  }

  .mat-error {
    font-size: 75%;
    margin-top: 0.6666666667em;
    top: calc(100% - 1.7916666667em);
  }
}
