@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('../assets/fonts/Roboto/roboto-100.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/Roboto/roboto-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/Roboto/roboto-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/Roboto/roboto-100.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/Roboto/roboto-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/Roboto/roboto-100.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/fonts/Roboto/roboto-300.eot');
  src: local(''),
  url('../assets/fonts/Roboto/roboto-300.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Roboto/roboto-300.woff2') format('woff2'),
  url('../assets/fonts/Roboto/roboto-300.woff') format('woff'),
  url('../assets/fonts/Roboto/roboto-300.ttf') format('truetype'),
  url('../assets/fonts/Roboto/roboto-300.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/fonts/Roboto/roboto-300italic.eot');
  src: local(''),
  url('../assets/fonts/Roboto/roboto-300italic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Roboto/roboto-300italic.woff2') format('woff2'),
  url('../assets/fonts/Roboto/roboto-300italic.woff') format('woff'),
  url('../assets/fonts/Roboto/roboto-300italic.ttf') format('truetype'),
  url('../assets/fonts/Roboto/roboto-300italic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/Roboto/roboto-regular.eot');
  src: local(''),
  url('../assets/fonts/Roboto/roboto-regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Roboto/roboto-regular.woff2') format('woff2'),
  url('../assets/fonts/Roboto/roboto-regular.woff') format('woff'),
  url('../assets/fonts/Roboto/roboto-regular.ttf') format('truetype'),
  url('../assets/fonts/Roboto/roboto-regular.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../assets/fonts/Roboto/roboto-500.eot');
  src: local(''),
  url('../assets/fonts/Roboto/roboto-500.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Roboto/roboto-500.woff2') format('woff2'),
  url('../assets/fonts/Roboto/roboto-500.woff') format('woff'),
  url('../assets/fonts/Roboto/roboto-500.ttf') format('truetype'),
  url('../assets/fonts/Roboto/roboto-500.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../assets/fonts/Roboto/roboto-500italic.eot');
  src: local(''),
  url('../assets/fonts/Roboto/roboto-500italic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Roboto/roboto-500italic.woff2') format('woff2'),
  url('../assets/fonts/Roboto/roboto-500italic.woff') format('woff'),
  url('../assets/fonts/Roboto/roboto-500italic.ttf') format('truetype'),
  url('../assets/fonts/Roboto/roboto-500italic.svg#Roboto') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/Roboto/roboto-700.eot');
  src: local(''),
  url('../assets/fonts/Roboto/roboto-700.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Roboto/roboto-700.woff2') format('woff2'),
  url('../assets/fonts/Roboto/roboto-700.woff') format('woff'),
  url('../assets/fonts/Roboto/roboto-700.ttf') format('truetype'),
  url('../assets/fonts/Roboto/roboto-700.svg#Roboto') format('svg');
}
