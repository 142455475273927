@import 'variables';

.eos-notification {
  color: $notification-text;
  padding: 20px !important;

  h4 {
    font-weight: 500;
    margin: 0 0 5px;
  }

  p {
    line-height: 18px;
    font-size: 12px;
    margin: 0;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.success {
    background-color: $notification-success-bg;

    h4 {
      color: $brand-success;
    }
  }

  &.error {
    background-color: $notification-danger-bg;

    h4 {
      color: $brand-dander;
    }
  }

  &.info {
    color: $notification-info-text;
    background-color: $notification-info-bg;
    border-color: $notification-info-border;
  }

  &.warning {
    color: $notification-warning-text;
    background-color: $notification-warning-bg;
    border-color: $notification-info-border;
  }

  &.eos-pass-recovery-notification p {
    &:first-of-type {
      margin-bottom: 16px;
    }

    a {
      color: $notification-text;
      text-decoration: none;
      margin-left: 6px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
