@import 'variables';
@import 'mixins';

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.eos-overlay-panel {
  width: 800px;

  .mat-dialog-container {
    padding: 0;

    .mat-dialog-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $grey-border;
      padding: 24px;
      margin: 0;

      h4 {
        margin: 0;
      }

      button {
        @include btn-icon-size(32px, 16px);
      }

      .mat-icon {
        @include icon-size(24px);
      }
    }

    .mat-dialog-content {
      padding: 24px;
      margin: 0;

      .mat-form-field,
      .eos-radio-group-container {
        font-size: 14px;
      }
    }

    .mat-dialog-actions {
      min-height: 36px;
      border-top: 1px solid $grey-border;
      padding: 24px;
      margin: 0;
    }
  }
}

/*
* === SIGN UP ===
*/
.eos-sign-up-success-overlay-panel,
.eos-sign-up-failure-overlay-panel {
  max-width: 450px !important;
}

#eos-sign-up-success-dialog {
  color: $success-text;
  background-color: $success-bg;
}

#eos-sign-up-failure-dialog {
  color: $danger-text;
  background-color: $danger-bg;
}

#eos-sign-up-success-dialog,
#eos-sign-up-failure-dialog {
  .mat-dialog-content {
    text-align: center;

    h4 {
      margin-top: 0;
    }

    p,
    i {
      font-size: 12px;
    }
  }

  .mat-dialog-actions button {
    color: $white;
    background-color: $brand-success;
  }
}

/*
* === VIEW NOTIFICATION ===
*/
#eos-view-notification-dialog {
  .mat-dialog-content {
    p {
      margin-top: 0;
      font-size: 14px;
    }

    input,
    textarea {
      color: rgba(0, 0, 0, .87);
    }
  }
}

/*
* === EMAIL ===
*/
#eos-email-dialog {
  .eos-email-dialog-order-by {
    margin-top: 20px;
  }
}

/*
* === WITH SEARCH BOX AND TABLE ===
*/
.eos-overlay-panel.eos-search-box-overlay-panel {
  .mat-dialog-content {
    padding: 0;

    .eos-search-box-container .eos-search-box-search-section > div:last-of-type {
      margin-bottom: 0;
    }

    .eos-table-container {
      padding: 0 24px;
    }
  }
}

/*
 * === COMPONENTS ===
*/
#eos-order-list-task-dialog,
#eos-order-list-note-dialog,
#eos-order-list-file-dialog {
  .mat-dialog-content {
    height: 354px;
  }
}

#eos-return-transfer-dialog,
#eos-confirm-dialog {
  .mat-dialog-content {
    h4 {
      margin: 0;
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
