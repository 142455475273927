@import 'variables';

.eos-auth-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  .eos-auth {
    max-width: 480px;
  }

  .eos-auth-content {
    position: relative;
    padding: 20px;

    &:before {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 220px;
      line-height: 0;
      z-index: -1;
      background-color: $brand-primary;
    }

    & > div {
      font-size: 0.75rem;
      text-align: center;

      a {
        color: $grey-50;
        text-decoration: none;
      }
    }
  }

  .mat-card {
    border-radius: 2px;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 10px 0 20px 0;
  }

  .mat-card-content {
    border-radius: 0 0 2px 2px;
    padding: 30px 20px;

    & > button {
      width: 100%;
      margin-top: 20px;
    }
  }

  .mat-form-field {
    margin-bottom: 20px;
  }
}
