@import 'variables';
@import 'mixins';

.eos-btn {
  @include text-overflow();
}

.eos-btn-primary {
  @include text-overflow();
  color: $white;
  background-color: $brand-primary;
}

.eos-btn-link-primary {
  @include text-overflow();
  color: $brand-primary;

  &:hover {
    text-decoration: underline;
  }
}

.eos-btn-icon-primary {
  color: $brand-primary;
}

.eos-btn-delete {
  color: $red;
}

.eos-btn-submit {
  @extend .eos-btn-primary;
  width: 100%;
  margin-top: 20px !important;
}
