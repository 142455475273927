/*
* === OVERRIDE MATERIAL THEME ===
*/
@import 'node_modules/@angular/material/theming';

$custom-typography: mat-typography-config(
  $font-family: 'Roboto, Helvetica Neue, Arial, sans-serif;'
);

@include mat-core($custom-typography);

/*
* === OVERRIDE BOOTSTRAP VARIABLES ===
*/
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1120px, // modified
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1088px, // modified
  xl: 1140px,
  xxl: 1320px
);

@import 'node_modules/bootstrap/scss/bootstrap-grid.scss';

/*
* === OVERRIDE AG GRID MATERIAL THEME ===
*/

/** Entire Alpine theme is loaded in the angular.json file **/
.ag-theme-material .ag-icon-none {
  --ag-icon-font-family: 'agGridAlpine';
}

/*
* === IMPORT SCSS FILES ===
*/
@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/fonts';
@import 'scss/auth';
@import 'scss/new-entity';
@import 'scss/batch-update';
@import 'scss/integration';
@import 'scss/shared/notifications';
@import 'scss/shared/menu';
@import 'scss/shared/table';
@import 'scss/shared/flag';
@import 'scss/shared/button';
@import 'scss/shared/chips-autocomplete';
@import 'scss/shared/search-box';
@import 'scss/shared/dialog';
@import 'scss/shared/panel';
@import 'scss/shared/editor';
@import 'scss/shared/form';
@import 'scss/shared/checkbox';

/*
* === BASE ===
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  position: relative;
}

body {
  position: relative;
  min-height: 100%;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-size: 100%;
  background-color: $bg-body;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
}

main {
  background-color: $bg-body;
  padding: 20px;

  & > .mat-card {
    padding: 0;
  }
}

/*
* === DIVIDER ===
*/
.eos-divider {
  height: 1px;
  border-right: 1px solid $grey-50;
  margin: 0 15px;
}

/*
* === SPINNER ===
*/
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $brand-primary;
}

/*
* === LOGO ===
*/
#eos-brand-logo,
.eos-brand-logo {
  display: flex;
  justify-content: center;
  align-items: center;

  &.close {
    justify-content: flex-start;
    padding: 6px;

    img {
      width: 48px;
    }
  }

  img {
    width: 120px;
  }
}

/*
* === LINK ===
*/
.eos-link {
  color: $brand-primary;
  text-decoration: none;

  &:hover,
  &:visited {
    color: $link-hover-color;
  }

  &:hover {
    text-decoration: underline;
  }
}

/*
* === UTILITIES ===
*/
.p-0 {
  padding: 0;
}

.p-20 {
  padding: 20px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.m-0 {
  margin: 0;
}

.mr-10 {
  margin-right: 10px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.fw-medium {
  font-weight: 500 !important;
}
