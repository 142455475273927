@import 'variables';
@import 'mixins';

.eos-company-new-container,
.eos-user-new-container,
.eos-email-template-new-container,
.eos-notification-event-edit-container,
.eos-developer-support-container {
  form {
    padding: 24px 12px;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;

    legend {
      font-size: 16px;
      font-weight: 500;
      color: $brand-primary;
      margin-bottom: 16px;
    }
  }

  .mat-datepicker-toggle {
    font-size: 20px;
  }
}

.eos-company-new-container,
.eos-user-new-container,
.eos-notification-event-edit-container,
.eos-developer-support-container {
  .mat-form-field {
    margin-bottom: 20px;
  }
}

.eos-email-template-new-container .eos-editor-container {
  margin: 0;
}

.eos-batch-payment-new-container {
  .eos-batch-payment-new-controls-container {
    display: flex;
    flex-direction: column;
    padding: 10px;

    &:last-of-type {
      .eos-batch-payment-new-controls {
        order: 2
      }

      p {
        order: 1;
      }
    }

    .eos-batch-payment-new-controls {
      display: flex;
      justify-content: space-between;
      padding: 10px 24px;

      button ~ button {
        margin: 0 0 0 10px;
      }
    }

    p {
      text-align: right;
      padding-right: 22px;
      margin: 0;
    }
  }
}
