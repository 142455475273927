@import 'variables';
@import 'mixins';

/*
* Fix https://github.com/angular/components/issues/11677
*/
.eos-overlay-pane {
  position: relative !important;
  left: 60px !important;
}

/* Header dropdown */
.eos-header-menu {
  .mat-menu-item,
  .mat-menu-item .mat-icon {
    color: $brand-primary-text;
  }
}

/* Nav menu */
.eos-nav-menu {
  background-color: $nav-bg-active;
  border-radius: 0 !important;
  box-shadow: none;
  margin: -60px 0 0 0;

  .mat-menu-content {
    padding: 0 !important;
  }

  .mat-menu-item,
  .mat-menu-item .mat-icon {
    color: $nav-text;
  }

  .mat-menu-item {
    &.active,
    &:hover,
    &:hover .mat-icon {
      color: $nav-text-active;
    }

    .mat-icon {
      @include icon-size(4px);
    }
  }
}
